<script setup>

    import { computed } from 'vue';
    const props = defineProps({
                    AttributeImg: {
                        type: Object,
                        required: true,
                        default: () => ({
                            source: '',
                            altText: 'Image sans alternatif',
                            titleText: 'Image sans titre',
                            className: '',
                            basePath: '',
                            width: '3600',
                            height: '3600'
                        }),
                        validator: (value) => value.source && value.altText
                    },
                });
    const baseSrc = props.AttributeImg.basePath || props.AttributeImg.source;
    // Génération automatique des valeurs pour srcset et sizes
    const srcset = computed(() => `
                                            ${baseSrc}-sm.webp 480w,
                                            ${baseSrc}-md.webp 800w,
                                            ${baseSrc}-lg.webp 1200w,
                                            ${baseSrc}-xl.webp 2400w,
                                            ${baseSrc}.webp 3600w
                                        `);
    const sizes = computed(() => `
                                            (max-width: 480px) 100vw,
                                            (max-width: 800px) 50vw,
                                            (max-width: 1200px) 33vw,
                                            (max-width: 2400px) 25vw,
                                            (max-width: 3200px) 20vw,
                                            16vw
                                        `);
</script>

<template>
    <img
        v-lazy="AttributeImg.source"
        :alt="AttributeImg.altText"
        :title="AttributeImg.titleText"
        :class="AttributeImg.className"
        :srcset="srcset"
        :sizes="sizes"
        :width="AttributeImg.width"
        :height="AttributeImg.height"
    />
</template>
